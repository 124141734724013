import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

/**
 * Survey done legend's modal component
 */
@Component({
  selector: 'legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent {

  /**
   * Labels
   */
  LABELS = [
    'Class'
  ];

  /**
   * @ignore
   */
  constructor(
    public bsModalRef: BsModalRef
  ) { }

}
