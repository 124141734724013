import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Vessel } from '../@shared/shared.models';
import { HttpClient } from '@angular/common/http';

/**
 * Service for handling pages
 */
@Injectable({
  providedIn: 'root'
})
export class PagesService {

  /**
   * @ignore
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Returns vessel list
   */
  getVesselList(params): Observable<Array<Vessel>> {
    return this.http.get<Array<Vessel>>('vessel/vessel_list', {params}).pipe(
      map(response => {
        return response;
      })
    );
  }




  /**
   * Returns signed in user's fullname
   */
  getUserName(): Observable<any> {
    return this.http.get<any>('account/name').pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Returns signed in user's permissions
   */
  getAllPermissions(): Observable<any> {
    return this.http.get<any>('account/all_user_permissions').pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Get user vessel groups
   */
  shipownerVesselGroups(): Observable<Array<any>> {
    return this.http.get<Array<any>>('account/shipowner_vessel_groups').pipe(
      map(response => {
        return response;
      })
    );
  }
}
