import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Service for checking permissions
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  /**
   * @ignore
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   *
   * Returns permissons for specific module
   *
   * @param moduleName Name of module for access to be granted
   *
   *
   */
  hasPermission(moduleName): Observable<any> {
    return this.http.get<any>('account/has_permission', { params: { module: moduleName } }).pipe(
      map(response => {
        return response;
      })
    );
  }
}
