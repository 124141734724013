import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ToasterService } from 'angular2-toaster';
import { PermissionService } from '../services/permission.service';
import { map } from 'rxjs/operators';

/**
 * Login RouteGuard
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  /**
   *
   * @ignore
   *
   */
  constructor(
    private http: PermissionService,
    private toastr: ToasterService
  ) { }

  /**
   *
   * Determines if user is logged in and then navigate to application
   *
   */
  canActivate(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let module = state.url.split('/')[1];
    if (module === 'vessels') {
      module = state.url.split('/')[3];
    }
    return this.http.hasPermission(module).pipe(
      map(response => {
        if (['administrators','users', 'user-logs', 'shipowners', 'report-files', 'other-files', 'email-notifications', 'change-password', 'how-to-use'].includes(module) || response['has_permission']) {
          return true;
        } else {
          this.toastr.pop('error', 'You have not permission to this site.');
          return false;
        }
      })
    );
  }

  /**
   *
   * Determines if user is logged in and then navigate to child routes of the application
   *
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(childRoute, state);
  }
}
