import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Alert, AlertsListGeneralReport, AlertsGeneralReportForOwner, GeneralReportForVesselGroup } from './list-of-machinery-with-alerts.models';

/**
 * Service for list of machinery with alerts page
 */
@Injectable({
  providedIn: 'root'
})
export class ListOfMachineryWithAlertsService {
  /**
   * @ignore
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * Returns fleet's general report
   */
  public getFleetGeneralReport(params): Observable<Array<AlertsListGeneralReport>> {
    return this.http.get<Array<AlertsListGeneralReport>>('fleet/fleet_general_report', { params }).pipe(
      map(response => {
        return response;
      })
    );
  }


  /**
   * Returns fleet's general report for Owner
   */
  public getFleetGeneralReportForOwner(params): Observable<Array<AlertsGeneralReportForOwner>> {
    return this.http.get<Array<AlertsGeneralReportForOwner>>('fleet/fleet_general_report_for_owner', { params }).pipe(
      map(response => {
        return response;
      })
    );
  }


  /**
* Returns Fleets's machinery condition
*
* @param options request options
*/
  public getVesselMachineryConditionReportForOneVesselGroup(options): Observable<any> {
    return this.http.get<any>('vessel/vessel_machinery_condition_report_for_one_vessel_group/' + options.id, { params: options.params }).pipe(
      map(response => {
        return response;
      })
    );
  }


  /**
  * Returns fleet's general report for Vessel Group
  */
  public getFleetGeneralReportForVesselGroup(params): Observable<Array<GeneralReportForVesselGroup>> {
    return this.http.get<Array<GeneralReportForVesselGroup>>('fleet/fleet_general_report_for_vessel_group', { params }).pipe(
      map(response => {
        return response;
      })
    );
  }


  /**
* Returns fleets's general report for one fleet
*/
  public getFleetGeneralReportForOneFleet(params): Observable<Array<AlertsListGeneralReport>> {
    return this.http.get<Array<AlertsListGeneralReport>>('fleet/fleet_general_report_for_one_fleet', { params }).pipe(
      map(response => {
        return response;
      })
    );
  }

  /**
   * Returns vessel's machinery contition report
   * @param options requested options
   */
  public getVesselMachineryConditionReport(options): Observable<Array<Alert>> {
    return this.http.get<any>('vessel/vessel_machinery_condition_report/' + options.id, { params: options.params }).pipe(
      map(response => {
        return response;
      })
    );
  }
  /**
   * Returns vessel's machinery list details
   * @param id requested id
   */
  public getVesselMachineryListDetail(id): Observable<Array<Alert>> {
    return this.http.get<any>('vessel/vessel_machinery_list_detail/' + id).pipe(
      map(response => {
        return response;
      })
    );
  }


  /**
* Returns Fleets's machinery condition
*
* @param options request options
*/
  public getVesselMachineryConditionReportForOneFleet(options): Observable<any> {
    return this.http.get<any>('vessel/vessel_machinery_condition_report_for_one_fleet/' + options.id, { params: options.params }).pipe(
      map(response => {
        return response;
      })
    );
  }


  /**
* Generate Device PDF 
*
* @param options request options
*/
  generateDevicePDF(params) {
    const request = new HttpRequest('POST', 'vessel/generate_device_pdf', params, {
      reportProgress: true,
      responseType: 'blob'
    });
    return this.http.request(request);
  }

}
